var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function () {
            this$1.$router.go(-1)
          },
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "start", justify: "start" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-3",
                  attrs: { color: "primary" },
                  on: {
                    click: function () {
                      this$1.$router.go(-1)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticStyle: { "min-height": "700px" },
              attrs: { lg: "6", cols: "12", md: "12", sm: "12" },
            },
            [
              _vm.isLoadingXero
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "table" },
                  })
                : _vm._e(),
              !_vm.isLoadingXero
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "12", cols: "12", md: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-3",
                              attrs: { "elevation-1": "" },
                            },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "white--text text-h5 mb-4",
                                  attrs: { color: "secondary" },
                                },
                                [_vm._v(" Invoice ")]
                              ),
                              _c("v-spacer"),
                              _c("AError", { attrs: { api: this.api } }),
                              _c(
                                "v-row",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", lg: "6", md: "12" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Company",
                                            },
                                            model: {
                                              value: _vm.data.company_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "company_name",
                                                  $$v
                                                )
                                              },
                                              expression: "data.company_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Address",
                                            },
                                            on: { change: _vm.checkAddress },
                                            model: {
                                              value: _vm.data.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression: "data.address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.isShowSetAsAddress
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-start",
                                            },
                                            [
                                              _c("v-checkbox", {
                                                staticStyle: {
                                                  "margin-top": "-20px",
                                                },
                                                attrs: {
                                                  label:
                                                    "Set as current address",
                                                },
                                                model: {
                                                  value:
                                                    _vm.data
                                                      .is_set_default_address,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "is_set_default_address",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.is_set_default_address",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", lg: "6", md: "12" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Invoice No.",
                                            },
                                            model: {
                                              value: _vm.data.invoice_no,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "invoice_no",
                                                  $$v
                                                )
                                              },
                                              expression: "data.invoice_no",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Description",
                                            },
                                            model: {
                                              value: _vm.data.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "data.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("ComponentDateModal", {
                                        attrs: {
                                          label: "Issued date",
                                          data: _vm.data.issued_at,
                                        },
                                        on: {
                                          updateDateData: function (resp) {
                                            this$1.data.issued_at = resp
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Client no",
                                            },
                                            model: {
                                              value: _vm.data.client_no,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "client_no",
                                                  $$v
                                                )
                                              },
                                              expression: "data.client_no",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Subtotal ( RM )",
                                              dense: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.data.subtotal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "subtotal",
                                                  $$v
                                                )
                                              },
                                              expression: "data.subtotal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Tax Amount ( RM )",
                                            },
                                            model: {
                                              value: _vm.data.tax_amount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "tax_amount",
                                                  $$v
                                                )
                                              },
                                              expression: "data.tax_amount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Total ( RM )",
                                            },
                                            model: {
                                              value: _vm.data.total,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.data, "total", $$v)
                                              },
                                              expression: "data.total",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-start" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: "Amount due ( RM )",
                                            },
                                            model: {
                                              value: _vm.data.amount_due,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "amount_due",
                                                  $$v
                                                )
                                              },
                                              expression: "data.amount_due",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex justify-end" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                loading:
                                                  _vm.isLoadingGeneratePdf,
                                                color: "primary",
                                              },
                                              on: {
                                                click: _vm.generateInvoice,
                                              },
                                            },
                                            [_vm._v(" Review invoice ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.isLoadingSendEmail,
                                              },
                                              on: { click: _vm.sendEmail },
                                            },
                                            [_vm._v(" Save modified invoice ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticStyle: { "min-height": "700px" },
              attrs: { lg: "6", sm: "12" },
            },
            [
              _vm.isLoadingPdf
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "image" },
                  })
                : _c("PageReviewXeroInvoice", { attrs: { url: _vm.pdfUrl } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }